
import React, { useState, useEffect } from 'react';
import './Credit.css';
import Post from './CreditPost';
import config from "../../config.js";


const Third = () => {
    const [posts, setPosts] = useState([]);
    const token = localStorage.getItem("token");

    //console.log("Inside Third"+token)
    useEffect(() => {
      getDataFromDb();
    }, [])

    const  getDataFromDb = () => {
              fetch(config.ip+'/api/files/getSignedContracts',
                {
                  headers: {
                    'x-access-token': token
                  }
                })
                  .then((userPosts) => userPosts.json())
                  .then((res) =>
                  {
                    //console.log(res);
                    setPosts( res )
                  }
                );
          };

    return (
        <div className="feed1">
          {
                posts.map(post => (
                    <Post
                        key={post._id}
                        name={post.signedContractURL}
                        mobileNumber={post.mobileNumber}
                        token={token}
                    />
                ))
            }

        </div>
    )
}

export default Third;
