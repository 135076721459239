import React, { useState, useEffect } from 'react';
import './Request.css';
import config from "../../config"
// components
import Post from './RequestPost';
//import PostTrack from "./Post/PostTrack";



const Feed = () => {
    const [posts, setPosts] = useState([]);

    const token = localStorage.getItem("token");
    useEffect(() => {
      getDataFromDb();
    }, [])
    

    const  getDataFromDb = () => {
              fetch(config.ip+'/api/files/getAllRequests',
                {
                  headers: {
                    'x-access-token': token
                  }
                })
                  .then((userPosts) => userPosts.json())
                  .then((res) =>
                  {
                    setPosts( res )
                  }
                );
          };
    const deleteAllRequests = () => {
      if(window.confirm("Bütün istəkləri silmək istədiyindən əminsən?"))
      {
        fetch(config.ip+'/api/files/deleteAllRequests',
                {
                  headers: {
                    'x-access-token': token
                  }
                }).then(resp => window.location.reload())
      }
      
    }
    return(
      <div className="feed">
          <div className="requests">
          <button onClick={() => deleteAllRequests()} style={{float: 'right'}}>Hamısını Sil</button>
            <h2>Gələn İstəklər</h2>
            
          {
                posts.map(post => (
                    <Post
                        key={post._id}
                        postID={post._id}
                        name={post.nameAndSurname}
                        money={post.amountOfMoney}
                        mobileNumber={post.mobileNumber}
                        city = {post.city}
                        token={token}
                        date={post.createdAt}

                    />
                ))
            }
          </div>
          </div>
    )
}

export default Feed;
