import React, {useState} from 'react';
import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
// components
import Login from './Components/Login2';


//Edited features
import Request from './Components/Screens/Request2/Request';
import History from './Components/Screens/HistoryFiles/SignedHistory';
import Users from './Components/Screens/Users/Users';
import SendCreditFile from './Components/Screens/SendCreditFile/CreditFile';
import Zamin from './Components/Screens/Zamin/Zamin';
import Backup from './Components/Screens/Backup/BackupToExcel';
import SignedZamins from './Components/Screens/Zamin/SignedZamin';
import SignedCreditFiles from './Components/Screens/SignedCreditFiles/Credit';
import RequestsWeb from './Components/Screens/RequestsWeb/RequestsWeb';

//import Navbar from "./Components/Navbar";
import SideBar from './Components/Sidebar';
import sidebar_menu from './constants/sidebar-menu';

// context api


const App = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [token, setToken] = useState();
    const [ userData, setUserData] = useState({
    token: undefined,
    user: undefined
    });

  const checkLoggedIn = () => {
    let tokenas = localStorage.getItem("token");
    if(tokenas === null){
      console.log("FalsE")
      return false;
    }
    else {
      console.log("TruE"+tokenas)
      return true;
    }
  }


    if(!checkLoggedIn())
    {
      return <Login setToken={setToken} />
    }
    return(
      <BrowserRouter>
        <div className='dashboard-container'>
        <SideBar menu={sidebar_menu} />
          
          <div className='dashboard-body'>
              <Routes>
            <Route path="/" element={<Request token={token}/>}/>
            <Route path="/first" element={<History token={token}/>}/>
            <Route path="/second" element={<SendCreditFile token={token}/>}/>
            <Route path="/third" element ={<SignedCreditFiles token={token}/> } />
            <Route path="/fourth" element ={<Zamin token={token}/> } />
            <Route path="/fifth" element ={<SignedZamins token={token}/> } />
            <Route path="/sixth" element ={<Users token={token} />} />
            <Route path="/seventh" element ={<Backup token={token} />} />
            <Route path="/web" element = {<RequestsWeb token={token} />} />
          </Routes>
          </div>
          </div>
      </BrowserRouter>


    )
  }




export default App;
