import DashboardIcon from '../assets/icons/email.png';
import ShippingIcon from '../assets/icons/search (1).png';
import ProductIcon from '../assets/icons/history.png';
import UserIcon from '../assets/icons/man.png';
import done from '../assets/icons/sign.png';
import zamin from '../assets/icons/guarantor.png';
import zamin2 from '../assets/icons/agreement.png';
import excel from '../assets/icons/excel.png';

const sidebar_menu = [
    {
        id: 1,
        icon: DashboardIcon,
        path: '/',
        title: 'İstəklər (Mobil)',
    },
    {
        id: 2,
        icon: DashboardIcon,
        path: '/web',
        title: 'İstəklər (Web)',
    },
    {
        id: 3,
        icon: ProductIcon,
        path: '/first',
        title: '1. Mərhələ',
    },
    {
        id: 4,
        icon: ShippingIcon,
        path: '/second',
        title: '2. Mərhələ',
    },
    {
        id: 5,
        icon: done,
        path: '/third',
        title: 'İmzalanmış Müqavilələr',
    },
    {
        id: 6,
        icon: zamin,
        path: '/fourth',
        title: 'Zamin Paneli',
    },
    {
        id: 7,
        icon: zamin2,
        path: '/fifth',
        title: 'İmzalanmış Zamin Müqavilələri',
    },
    {
        id: 8,
        icon: UserIcon,
        path: '/sixth',
        title: 'İstifadəçilər'
    },
    {
        id: 9,
        icon: excel,
        path: '/seventh',
        title: 'Excel Backup'
    }
]

export default sidebar_menu;