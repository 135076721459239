import React from "react";
import axios from "axios";
import DragDrop from "./DragDrop";
import config from "../../config"

class Second extends React.Component {
  state = {
    percentage: "",
    amount: "",
    time: "",
    fin: "",
    postTracks : [],
    token: localStorage.getItem("token"),
    searchedNumber: '',
    searchedName: '',
    searchedSurname: '',
    showSpan: false,
    openDragDrop: false
  };


  findUser = (number) => {
    axios.post(config.ip+'/api/posts/getUser',
    { 
        token: this.state.token, 
        mobileNumber: number
    })
      .then(response => {
        
        console.log(response.data)
        this.setState({searchedName: response.data.name})
        this.setState({searchedSurname: response.data.surname})
        this.setState({showSpan: true})
      })
      .catch(err =>
      {
        console.log(err)
      });
}

  getPostTrack(chosenNumber) {
    //console.log("Choosen number")
    //console.log(chosenNumber)
    axios.post(config.ip+'/api/posts/getPostTracks',{
      mobileNumber: chosenNumber 
    },
    {
      headers: {
        'x-access-token': this.state.token
      }})
      .then(data => 
        {
          //console.log("PostTracks")
          //console.log(data.data)
          this.setState({postTracks: data.data.postTracks})
        })
  }

  render() {
    return (
        <div>
<div style={{display: 'flex',
    flexDirection: 'row'}}>
         
         <input
           placeholder="Mobil nömrəyə görə axtar"
           value={this.state.searchedNumber}
           onChange={(event) => this.setState({searchedNumber: event.target.value})}
         />

         <button onClick={() => this.findUser(this.state.searchedNumber)}><img src={require('../../../assets/icons/search.png')}/></button>
         {this.state.showSpan &&
           <span style={{marginLeft: 15, border: '1px solid black', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}} onClick={() => 
               {
                   this.getPostTrack(this.state.searchedNumber);

                   this.setState({openDragDrop: true})
               }
           }>
               {this.state.searchedName}, {this.state.searchedSurname}, {this.state.searchedNumber}

           </span>
         }
     
     </div>
            <div style={{width: '50%'}}>
      {this.state.openDragDrop && <DragDrop token={this.state.token} number={this.state.searchedNumber} postTracks={this.state.postTracks} />}
      </div>
        </div>
      
    );
  }
}
export default Second;
